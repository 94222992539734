<template>
    <div class="level_index level_indexII">


        <div class="statisticsBox">

            <div class="statisticsCell">
                <p class="title">数据统计</p>

                <statistics :label="'收集'" :num="'5'" :weight="'15'" :colorVal="'#3A8FEA'" :imgVal="'fa-dropbox'">
                </statistics>
                <statistics :label="'入库'" :num="'5'" :weight="'15'" :colorVal="'#0FACD5'" :imgVal="'fa-inbox'">
                </statistics>
                <statistics :label="'出库'" :num="'5'" :weight="'15'" :colorVal="'#36CFC9'" :imgVal="'fa-sign-out'">
                </statistics>

            </div>

            <div class="statisticsCell">
                <p class="title">出库预警</p>

                <statistics :typeVal="36" :num="'5'" :weight="'15'" :colorVal="'#F9954C'" :imgVal="'fa-warning'">
                </statistics>
                <statistics :typeVal="48" :num="'0'" :weight="'0'" :colorVal="'#FA5A5C'" :imgVal="'fa-window-close-o'">
                </statistics>

            </div>

        </div>


        <div class="search">

            <el-form :inline="true" :model="search">
                <el-form-item prop="title">
                    <!-- <el-input v-model="search.title" placeholder="请输入关键字" /> -->
                    <!-- <el-select v-model="value1" clearable placeholder="请选择入库人" class="SelfSelect">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->
                    <el-select v-model="value2" clearable placeholder="请选择所属医院" class="SelfSelect">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value3" clearable placeholder="请选择类型" class="SelfSelect">
                        <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="value4" clearable placeholder="请选择科室" class="SelfSelect">
                        <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <el-select v-model="value5" clearable placeholder="请选择入库时间" class="SelfSelect">
                        <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select> -->

                    <el-select v-model="value5" clearable placeholder="请选择状态" class="SelfSelect">
                        <el-option v-for="item in options5" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                </el-form-item>


                <el-form-item>
                    <el-button type="primary" @click="getList(data.size)">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>
                    <!-- <el-button class="block" @click="toDel()">批量删除</el-button> -->
                </el-form-item>

                <el-form-item>
                    <div @click="warn">
                        <warn />
                    </div>
                </el-form-item>







                <el-form-item style="float: right;margin: 0;">

                    <el-button type="primary" @click="toSearch" style="">
                        高级搜索
                    </el-button>

                </el-form-item>

            </el-form>


        </div>


        <div class="OutputBox">

            <span><i class="fa fa-line-chart" aria-hidden="true"></i>
                <small>医废汇总：当前封箱共2458包，15352.56KG；入库共2458包，15352.56KG；出库共2458包，15352.5</small>
            </span>

            <el-button class="block" @click="">明细导出</el-button>
            <el-button class="block" @click="">汇总导出</el-button>
        </div>


        <Fanfu-Table class="FanfuTableBox FanfuTableBoxI" v-model="select" :data="data" :search-height="50"
            :loading="loading" :get-list="getList" stripe border>



            <el-table-column show-overflow-tooltip prop="num" label="医废编号" align="left" />
            <el-table-column show-overflow-tooltip prop="hospital" label="医院" align="left" />
            <el-table-column show-overflow-tooltip prop="Department" label="科室" align="left" />
            <el-table-column show-overflow-tooltip prop="type" label="类型" align="left" width="" />

            <el-table-column show-overflow-tooltip prop="number" label="包数" align="left" width="" />

            <el-table-column show-overflow-tooltip prop="weight" label="收集重量(KG)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="收集状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="weight" label="封箱重量(Kg)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="封箱状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="weight" label="入库重量(Kg)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="入库状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="weight" label="出库重量(KG)" align="left" width="" />
            <el-table-column show-overflow-tooltip prop="state" label="出库状态" align="left" width="">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" v-if="scope.row.state">已出库</el-button>
                    <el-button type="danger" size="mini" v-else>未出库</el-button>
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="Third" label="科室交接人员签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员收集签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员入库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="暂存处人员入库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="转运人员出库签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="Third" label="第三方转运人员签字" align="center" width="">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="scope.row.Third" class="CoverImage">
                </template>
            </el-table-column>



            <el-table-column show-overflow-tooltip prop="time" label="封箱时间" align="left" />
            <el-table-column show-overflow-tooltip prop="time" label="入库时间" align="left" />
            <el-table-column show-overflow-tooltip prop="time" label="出库时间" align="left" />


            <el-table-column show-overflow-tooltip label="操作" align="center" width="67">
                <template slot-scope="scope">
                    <div class="operate">
                        <!-- <el-button type="primary" @click="edit(scope.row)" v-if="scope.row.state" class="operationBtn">
                            恢复
                        </el-button> -->
                        <!-- v-if="!scope.row.state" -->
                        <el-button type="danger" @click="del(scope.row)" class="operationBtn">
                            删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>


        </Fanfu-Table>


        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />

    </div>
</template>

<script>
    import Search from './components/WasteOutSearch'

    import statistics from './components/statistics.vue'
    import warn from './components/warn.vue'

    import resizeDetector from 'element-resize-detector'



    const list = [{
            num: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            number: 10,
            weight: 100,
            state: 1,
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            time: "2022-02-15 10:15:20",
        },
        {
            num: "YF20220216152146",
            hospital: "天津市双街镇社区卫生服务中心",
            Department: "行政四楼",
            type: "感染性",
            number: 10,
            weight: 100,
            state: 0,
            Third: "http://iph.href.lu/100x200?text=王五&fg=444444&bg=cccccc",
            time: "2022-02-15 10:15:20",
        },

    ]



    export default {
        components: {
            Search,
            statistics,
            warn
        },
        data() {
            return {
                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],
                data: {
                    list,
                    page: 1,
                    size: 15,
                    total: list.length,

                    // objectS: [
                    //     [7, 0, 1, 4],
                    //     [8, 0, 1, 4],
                    // ]
                    // // 列 行 横 竖

                },




                options1: [{
                    value: '选项1',
                    label: '张三'
                }, {
                    value: '选项2',
                    label: '李四'
                }],
                value1: '',

                options2: [{
                    value: '选项1',
                    label: '天津市双街镇社区卫生服务中心'
                }, {
                    value: '选项2',
                    label: '天津市西青区华苑日华里社区卫生服务中心'
                }],
                value2: '',

                options3: [{
                    value: '选项1',
                    label: '感染性'
                }, {
                    value: '选项2',
                    label: '放射性'
                }],
                value3: '',

                options4: [{
                    value: '选项1',
                    label: '内科一室'
                }, {
                    value: '选项2',
                    label: '实验楼一楼'
                }],
                value4: '',

                options5: [{
                    value: '选项1',
                    label: '今天'
                }, {
                    value: '选项2',
                    label: '昨天'
                }, {
                    value: '选项3',
                    label: '前天'
                }],
                value5: '',



            }
        },
        mounted() {
            this.modifyLayout();
        },
        created() {
            this.modifyLayout();
        },
        beforeMount() {
            this.modifyLayout();
        },
        beforeUpdate() {
            this.modifyLayout();
        },
        updated() {
            this.modifyLayout();
        },
        created() {
            this.getList()
        },
        methods: {


            warn() {

                console.log("日期相隔天数", this.DaysBetweenDates("2023-7-26", "2023-1-16"));
            },


            // 查询超48小时未送医废
            notDataBy48Houser() {
                this.$http.get('/api/number/notDataBy48Houser', {

                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            // 超36小时未处理
            dataBy36Houser() {
                this.$http.get('/api/number/dataBy36Houser', {}).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },
            // 超48小时未处理
            dataBy48Houser() {
                this.$http.get('/api/number/dataBy48Houser', {}).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                }).catch((err) => {
                    console.log(err)
                });
            },




            getList(size) {
                if (size) {
                    this.data.page = 1
                    this.data.size = size
                }
                this.loading = true
                this.select = []
                this.$nextTick(() => {
                    const start = this.data.size * (this.data.page - 1)
                    const end = start + this.data.size
                    this.data.total = list.length
                    const data = []
                    for (let i = start; i < end; i++) {
                        if (list[i]) {
                            data.push(list[i])
                        }

                    }
                    this.data.list = data
                    setTimeout(() => {
                        this.loading = false
                    }, 1000)

                    setTimeout(() => {
                        this.modifyLayout();
                    }, 1100)


                })
            },
            view(row) {
                console.log(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [
                        h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();

                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });
            },


            toDel() {
                // this.$refs.searchRef.open()
                var row = 1;
                console.log(row)

                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('span', null, '您确定要删除所选信息吗？删除后可能会导致数据丢失且删除操作不能撤销！'),

                    ]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';
                            setTimeout(() => {
                                done();
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false;
                                }, 300);
                            }, 3000);
                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    });
                });

            },



            toSearch() {
                this.$refs.searchRef.open()
            },







            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;


                    var a = 141 + 60 + 0 + height + 140;
                    if ($(".level_indexII .FanfuTableBox")[0]) {

                        $(".level_indexII .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' +
                            a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 70 + height + 140;
                    if ($(".level_indexII .el-table")[0]) {

                        $(".level_indexII .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');

                    }


                });

            }

        },


    }
</script>

<style lang="scss" scoped>
    .statisticsBox {
        // border: 1px red solid;
        // box-sizing: content-box;
        // height: 150px;
        margin-bottom: 10px;
        display: flex;

        position: relative;

        .statisticsCell {

            border: 1px solid #ECECEC;
            box-sizing: content-box;
            // height: 150px;
            flex: 2;
            margin-left: 10px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: nowrap;


            .title {
                // border: 1px red solid;
                border-left: 1px #6A83FC solid;
                box-sizing: content-box;

                // width: 58px;
                // height: 15px;
                // line-height: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2F2F2F;
                margin: 5px 0px;
                padding: 0px 10px;

                position: absolute;

            }


        }

        .statisticsCell:nth-of-type(1) {
            flex: 3;
            margin-left: 0px;
        }

    }

    .SelfSelect {
        width: 150px;
        margin-right: 10px;

    }

    .OutputBox {
        // border: 1px red solid;
        // box-sizing: content-box;

        margin: -5px 0 10px 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6A83FC;
        line-height: 20px;
        /* height: 30px; */
    }

    .OutputBox small {
        margin: 0 10px;
    }
</style>